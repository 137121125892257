import React, { useState, useRef } from "react"
import Layout from "../../../components/Layout"
import SEO from "../../../components/SEO/SEO"
import NavBar from "../../../components/NavBar/NavBar"
import SeeAlso from "../../../components/SeeAlso/SeeAlso"
import SmallImg from "../../../components/Image/SmallImg"

import {
  Title,
  SubTitle,
  Select,
  Input,
  Button,
  Field,
  Control,
  Columns,
  Column,
  StaticButton,
} from "../../../StyleComponents/styles"
import { FlexDiv } from "../../../StyleComponents/pagesStyle"

const navRoadArray = [
  { name: "Calculator", link: "/calculator/" },
  {
    name: "Shopping Calculator",
    link: "/shopping-calculator/",
  },
]
const seeAlsoArray = [
  "/percentage-calculator",
  "/kite-size-calculator",
  "/mortgage-loan-calculator",
  "/tax-cost-calculator",
  "/tip-calculator",
  "/fuel-consumption-calculator",
  "/age-calculation",
]

function ShoppingCalculator(props) {
  let [TotalPrice, setTotalPrice] = useState("")
  let [taXX, settaXX] = useState("")
  let [discountXX, setdiscountXX] = useState("")
  let [result, setresult] = useState("")
  let unitSelector = useRef("")

  function TotalPriceC(e) {
    setTotalPrice(e.target.value)
  }
  function taXXC(e) {
    settaXX(e.target.value)
  }
  function discountCC(e) {
    setdiscountXX(e.target.value)
  }

  function calculation() {
    TotalPrice = Number(TotalPrice)
    taXX = Number(taXX)
    discountXX = Number(discountXX)
    let reSultOfPercent, totalAfterTax, Pretaxtotal

    if (taXX < 0 || taXX === "") {
      totalAfterTax = TotalPrice
    } else {
      reSultOfPercent = (TotalPrice / 100) * taXX
      totalAfterTax = TotalPrice + reSultOfPercent
    }
    Pretaxtotal = (TotalPrice / 100) * discountXX
    Pretaxtotal = TotalPrice - Pretaxtotal
    let reSultOfPercentDiscount = (totalAfterTax / 100) * discountXX

    let ToTaL = totalAfterTax - reSultOfPercentDiscount

    let unit = unitSelector.current.value
    setresult(
      <p className="h4">
        Pretax total : {Pretaxtotal.toFixed(2)} {unit}
        <br />
        Tax is : {reSultOfPercent.toFixed(2)} {unit}
        <br />
        Discount is : {reSultOfPercentDiscount.toFixed(2)} {unit}
        <br /> After-tax Total Price : {ToTaL.toFixed(2)} {unit}
      </p>
    )
  }

  let [quantity, setquantity] = useState("1")
  let [price, setprice] = useState("1.00")
  let [tax, settax] = useState("0.0")
  let [discount, setdiscount] = useState("0.00")
  let [total, settotal] = useState("0.00")

  function quantityC(e) {
    setquantity(e.target.value)
  }
  function priceC(e) {
    setprice(e.target.value)
  }
  function taxC(e) {
    settax(e.target.value)
  }
  function discountC(e) {
    setdiscount(e.target.value)
  }

  function calculate() {
    total = quantity * price
    let totalAfterTax, discountVolume, reSultOfTax
    if (tax < 1 || tax === "") {
      totalAfterTax = total
    } else {
      reSultOfTax = (total / 100) * tax
      totalAfterTax = total + reSultOfTax
    }
    discountVolume = (totalAfterTax / 100) * discount
    // totalAfterTax += reSultOfTax
    totalAfterTax -= discountVolume
    totalAfterTax = totalAfterTax.toFixed(2)
    settotal(totalAfterTax)
    return false
  }

  return (
    <Layout location={props.location}>
      <SEO
        title="Shopping Calculator"
        description="Shopping Calculator, Enter Quantity of Order, the Unit Price with Tax Rate (%), if you have Discount and press calculate."
        keywords={[
          "Shopping Calculator, shopping tax calculator, total shopping calculation, unit price calculation,Calculate Percentage Discount, Percentage Tax calculator, calculate shopping total price.",
        ]}
      />

      <NavBar listPages={navRoadArray} />
      <div className="container">
        <Title>Shopping Calculator</Title>

        <SubTitle>
          Discount and sales tax calculator for Wholesale Shopping
        </SubTitle>

        <Columns>
          <Column>
            <Field>
              <Control>
                <StaticButton>Quantity</StaticButton>
              </Control>
              <Control className="expandedWidth">
                <Input
                  radius="0"
                  className="borderedRight"
                  type="number"
                  name="quantity"
                  value={quantity}
                  onChange={quantityC}
                  min="1"
                  required
                />
              </Control>
            </Field>

            <Field>
              <Control>
                <StaticButton>Price Per Unit</StaticButton>
              </Control>
              <Control className="expandedWidth">
                <Input
                  radius="0"
                  className="borderedRight"
                  type="number"
                  name="price"
                  min="0"
                  value={price}
                  onChange={priceC}
                  required
                />
              </Control>
            </Field>

            <Field>
              <Control>
                <StaticButton>Tax Rate (%)</StaticButton>
              </Control>
              <Control className="expandedWidth">
                <Input
                  radius="0"
                  className="borderedRight"
                  type="number"
                  name="tax"
                  min="0"
                  value={tax}
                  onChange={taxC}
                  required
                />
              </Control>
            </Field>

            <Field>
              <Control>
                <StaticButton>Discount (%)</StaticButton>
              </Control>
              <Control className="expandedWidth">
                <Input
                  radius="0"
                  className="borderedRight"
                  type="number"
                  min="0"
                  name="discount"
                  onChange={discountC}
                  value={discount}
                  required
                />
              </Control>
            </Field>
            <Button
              borderColor="#1da1f2"
              color="#1da1f2"
              hoverColor="white"
              hoverBorderColor="#1da1f2"
              hoverBackgroundColor="#1da1f2"
              value="Calculate"
              className="mb-3"
              onClick={calculate}
            >
              Calculate
            </Button>

            <Field>
              <Control>
                <StaticButton>Total</StaticButton>
              </Control>
              <Control className="expandedWidth">
                <Input
                  radius="0"
                  className="borderedRight"
                  type="text"
                  name="total"
                  value={total}
                  disabled
                  readOnly
                />
              </Control>
            </Field>
          </Column>
          <Column>
            <FlexDiv maxWidth="300px" margin="auto">
              <SmallImg filename="shop.png" alt="shoping calculator" />
            </FlexDiv>
          </Column>
        </Columns>
        <br />

        <FlexDiv maxWidth="500px" margin="auto">
          <SubTitle>Discount and sales tax calculator</SubTitle>
          <Field>
            <Control>
              <StaticButton>Total Price</StaticButton>
            </Control>
            <Control className="expandedWidth">
              <Input
                radius="0"
                type="number"
                min="0"
                name="price"
                value={TotalPrice}
                onChange={TotalPriceC}
                required
              />
            </Control>
            <Control>
              <Select>
                <select
                  className="staticSelect"
                  aria-label="Total-Price"
                  ref={unitSelector}
                >
                  <option value="&#36;">&#36;</option>
                  <option value="&#162;">&#162;</option>
                  <option value="&#163;">&#163;</option>
                  <option value="&#165;">&#165;</option>
                  <option value="&#8355;">&#8355;</option>
                  <option value="&#8356;">&#8356;</option>
                  <option value="&euro;">&euro;</option>
                </select>
              </Select>
            </Control>
          </Field>

          <Field>
            <Control>
              <StaticButton>Tax Rate (%)</StaticButton>
            </Control>
            <Control className="expandedWidth">
              <Input
                radius="0"
                className="borderedRight"
                type="number"
                name="tax"
                min="0"
                value={taXX}
                onChange={taXXC}
                required
              />
            </Control>
          </Field>

          <Field>
            <Control>
              <StaticButton>Discount (%)</StaticButton>
            </Control>
            <Control className="expandedWidth">
              <Input
                radius="0"
                className="borderedRight"
                type="number"
                name="discount"
                min="0"
                onChange={discountCC}
                value={discountXX}
                required
              />
            </Control>
          </Field>

          <Button
            borderColor="#1da1f2"
            color="white"
            backgroundColor="#1da1f2"
            hoverColor="#1da1f2"
            hoverBorderColor="#1da1f2"
            onClick={calculation}
          >
            Calculate
          </Button>

          <div>{result}</div>
        </FlexDiv>
        <br />
        <p>
          Use shopping calculator to calculate the Percentage of Discount And
          Percentage of Tax, see the price of tax, the price of discount, and
          the Total Price.
        </p>
        <br />
        <SeeAlso seeAlsoList={seeAlsoArray} />
        <br />
      </div>
    </Layout>
  )
}

export default ShoppingCalculator
